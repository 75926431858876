// @ts-nocheck
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "./firebase.config";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {collection, doc, getFirestore, QueryDocumentSnapshot, WithFieldValue} from "firebase/firestore";
import {Competition, Player} from "src/types/competitions";

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestoreDB = getFirestore();

export const Providers = {google: new GoogleAuthProvider()};


export const competitionConverter = {
    toFirestore(competition: WithFieldValue<Competition>) {
        return {id: competition.id, name: competition.name, events: competition.events} as Competition;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
    ) {
        return snapshot.data() as Competition;
    }
};

export const playerConverter = {
    toFirestore(player: WithFieldValue<Player>) {
        return player as Player;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
    ) {
        return snapshot.data() as Player;
    }
};

export const competitionCollection = collection(firestoreDB, 'competitions').withConverter(competitionConverter);
export const competitionDocumentRef = (competitionId: string) => doc(firestoreDB, `competitions/${competitionId}`).withConverter(competitionConverter);
export const playerCollection = collection(firestoreDB, 'players').withConverter(playerConverter);
export const playerDocumentRef = (playerId: string) => doc(firestoreDB, `players/${playerId}`).withConverter(playerConverter);
