import {useEffect, useState} from "react";
import {isUndefined} from "src/utils/collectionUtils";
import {isFunction} from "src/utils/objectUtils";

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => getStorageValue<T>(key, defaultValue));

  const setStorageValue = (value: T | ((previousValue: T) => T)) => {
    // @ts-ignore
    const newValue = isFunction(value) ? value(storedValue) : value;
    isUndefined(storedValue) ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    setStoredValue(newValue);
  };

  useEffect(() => {
    setStoredValue(getStorageValue(key, defaultValue));
  }, [key, JSON.stringify(defaultValue)]);

  return [storedValue, setStorageValue] as [typeof storedValue, typeof setStorageValue];
};

const getStorageValue = <T>(key: string, defaultValue: T) => {
  const saved = localStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : undefined;
  return initial || defaultValue;
};
