// @ts-nocheck
import {NavigationBar} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import {competitionCollection, competitionDocumentRef} from "src/config/firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    lighten,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import Spacer from "src/components/Spacer/Spacer";
import {toList} from "src/utils/collectionUtils";
import React, {useState} from "react";
import {AdditionalPoints, Competition, Team} from "src/types/competitions";
import {updateDoc} from "@firebase/firestore";
import {TeamColours} from "src/screens/Home";

const Admin = () => {
    const [competitions] = useCollectionData(competitionCollection)

    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <ContentWrapper>
                    <Spacer spacing={2}>
                        <Card>
                            <CardHeader title={"Admin"}/>
                            <CardContent>
                                {
                                    // @ts-ignore
                                    <AdditionalPointsPerComp competitions={toList(competitions)}/>
                                }
                            </CardContent>
                        </Card>
                    </Spacer>
                </ContentWrapper>
            </PageWrapper>
        </>
    )
};

const AdditionalPointsPerComp = ({competitions}: { competitions: Competition[] }) => {
    return competitions.map((competition, compIndex) => (
        <Box sx={{ml: compIndex}}>
            <div>{competition.name}</div>
            {competition.events.map((event, eventIndex) => (
                <Box sx={{ml: Math.max(4, compIndex * eventIndex)}}>
                    <br/>
                    <div>{event.name}</div>
                    <Table sx={{border: '1px solid light-grey'}}>
                        <TableBody>
                            <TableRow>
                                {event.matches.map(match => (
                                    <TableCell align={"center"}>
                                        {match.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                {event.matches.map(match => <PointsInput key={match.id}
                                                                         additionalPoints={match.additionalPoints}
                                                                         onChange={(team, value) => {
                                                                             updateDoc(competitionDocumentRef(competition.id), {
                                                                                 ...competition,
                                                                                 events: competition.events.map(compEvent => {
                                                                                     if (event.id === compEvent.id) {
                                                                                         return {
                                                                                             ...compEvent,
                                                                                             matches: compEvent.matches.map(compMatch => {
                                                                                                 if (match.id === compMatch.id) {
                                                                                                     return {
                                                                                                         ...compMatch,
                                                                                                         additionalPoints: {
                                                                                                             ...compMatch.additionalPoints,
                                                                                                             [team]: value
                                                                                                         }
                                                                                                     };
                                                                                                 }
                                                                                                 return compMatch;
                                                                                             })
                                                                                         }
                                                                                     }
                                                                                     return compEvent;
                                                                                 })
                                                                             })
                                                                         }}/>)}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            ))}
        </Box>

    ))
}

const PointsInput = ({additionalPoints, onChange}: {
    additionalPoints: AdditionalPoints,
    onChange: (team: Team, val: number) => void
}) => {
    const [temp, setTemp] = useState<AdditionalPoints>(additionalPoints);

    return (
        <TableCell>
            <Stack direction={"column"} alignItems={"center"}
                   sx={{borderRadius: '4px'}}>
                <Box sx={{
                    width: '100%',
                    color: 'white',
                    background: TeamColours[Team.USA],
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Spacer spacing={0}>
                        <Stack direction={"row"} justifyContent={"space-between"}
                               spacing={2}>
                            <Button
                                sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    height: '60px',
                                    fontSize: '2rem',
                                    color: 'white',
                                }} onClick={() => {
                                setTemp(val => ({
                                    ...temp,
                                    [Team.USA]: Math.max(-11, temp[Team.USA] - 1)
                                }))
                            }}>-</Button>
                            <Stack direction={'column'}
                                   sx={{
                                       minWidth: '50px',
                                       fontSize: '2rem',
                                       color: 'white',
                                   }}
                                   alignItems={'center'}
                                   justifyContent={'center'}>{temp[Team.USA]}</Stack>
                            <Button
                                sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    height: '60px',
                                    fontSize: '2rem',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    setTemp(val => ({
                                        ...temp,
                                        [Team.USA]: Math.min(11, temp[Team.USA] + 1)
                                    }))
                                }}>+</Button>
                        </Stack>
                        <Button
                            fullWidth={true}
                            size={"small"}
                            variant={"text"}
                            sx={{
                                borderRadius: 0,
                                color: 'white',
                                background: TeamColours[Team.USA],
                                '&:hover': {
                                    background: lighten(TeamColours[Team.USA], 0.2)
                                }
                            }}
                            onClick={() => {
                                if (temp) onChange(Team.USA, temp[Team.USA])
                            }}>SAVE</Button>
                    </Spacer>
                </Box>
                <Box sx={{
                    width: '100%',
                    color: 'white',
                    background: TeamColours[Team.EUROPE],
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Spacer spacing={0}>
                        <Stack direction={"row"} justifyContent={"space-between"}
                               spacing={2}>
                            <Button
                                sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    height: '60px',
                                    fontSize: '2rem',
                                    color: 'white',
                                }} onClick={() => {
                                setTemp(val => ({
                                    ...temp,
                                    [Team.EUROPE]: Math.max(-11, temp[Team.EUROPE] - 1)
                                }))
                            }}>-</Button>
                            <Stack direction={'column'}
                                   sx={{
                                       minWidth: '50px',
                                       fontSize: '2rem',
                                       color: 'white',
                                   }}
                                   alignItems={'center'}
                                   justifyContent={'center'}>{temp[Team.EUROPE]}</Stack>
                            <Button
                                sx={{
                                    width: '40px',
                                    minWidth: '40px',
                                    height: '60px',
                                    fontSize: '2rem',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    setTemp(val => ({
                                        ...temp,
                                        [Team.EUROPE]: Math.min(11, temp[Team.EUROPE] + 1)
                                    }))
                                }}>+</Button>
                        </Stack>
                        <Button
                            fullWidth={true}
                            size={"small"}
                            variant={"text"}
                            sx={{
                                borderRadius: 0,
                                color: 'white',
                                background: TeamColours[Team.EUROPE],
                                '&:hover': {
                                    background: lighten(TeamColours[Team.EUROPE], 0.2)
                                }
                            }}
                            onClick={() => {
                                if (temp) onChange(Team.EUROPE, temp[Team.EUROPE])
                            }}>SAVE</Button>
                    </Spacer>
                </Box>
            </Stack>
        </TableCell>
    )
}

export default Admin;
