// @ts-nocheck
import {Masonry} from "@mui/lab";
import {NavigationBar} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import {auth, playerCollection} from "src/config/firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Stack} from "@mui/material";
import {useAuthState} from "react-firebase-hooks/auth";
import React from "react";
import {Link, useParams} from "react-router-dom";
import {toList} from "src/utils/collectionUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const Awards = () => {
    const {competitionId} = useParams();
    const [user] = useAuthState(auth);
    const [players] = useCollectionData(playerCollection)

    if (!user || !players) return null;

    const awards = players
        .reduce<Record<string, { name: string, year: number }[]>>((obj, player) =>
            player.competitionHistory
                // @ts-ignore
                .filter(comp => competitionId ? comp.competitionId === competitionId : true)
                // @ts-ignore
                .reduce((o, comp) =>
                    // @ts-ignore
                    comp.awards.reduce((ob, award) => ({
                        ...ob,
                        [award]: [...toList(ob[award]), {name: player.name, year: comp.year}]
                    }), o), obj), {})


    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <ContentWrapper>
                    <Breadcrumbs sx={{mt: -3, mb: 1}}>
                        <Button component={Link}
                                to={`/`}
                                style={{color: 'inherit', textDecoration: 'none'}}
                                startIcon={<ArrowBackIcon fontSize={"small"}/>}>
                            Home
                        </Button>
                    </Breadcrumbs>

                    {
                        Object.keys(awards).length === 0 &&
                        <Box sx={{
                            padding: '3rem 0',
                            textAlign: 'center'
                        }}>No awards given yet</Box>
                    }

                    {
                        Object.keys(awards).length > 0 && (
                            <Masonry columns={[1, 2, 3, 4, 5]} spacing={2}>
                                {
                                    Object.entries(awards).map(([award, names]) => {
                                        return (
                                            <Card>
                                                <CardHeader title={
                                                    <Stack direction={"row"} alignItems={"center"}>
                                                        <EmojiEventsIcon fontSize={"small"} color={"success"}/>
                                                        <span style={{marginLeft: '1rem'}}>{award}</span>
                                                    </Stack>
                                                }/>
                                                <CardContent>
                                                    {
                                                        names
                                                            .reduce<{ name: string, years: number[] }[]>((arr, {
                                                                name,
                                                                year
                                                            }) => {
                                                                const currentVal = arr.find(o => o.name === name);
                                                                const val = {
                                                                    name,
                                                                    years: [...toList(currentVal?.years), year]
                                                                }

                                                                return [...arr.filter(o => o.name !== name), val];
                                                            }, [])
                                                            .sort((a, b) => {
                                                                if (b.years.length === a.years.length) {
                                                                    return a.name.localeCompare('' + b.name)
                                                                }
                                                                return b.years.length - a.years.length;
                                                            })
                                                            .map(({name, years}) => (
                                                                <Stack direction={"row"} justifyContent={"space-between"}>
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        {competitionId ? null : <span>{years.length}</span>}
                                                                        <span>{name}</span>
                                                                    </Stack>
                                                                    <span>{years.join(', ')}</span>
                                                                </Stack>
                                                            ))
                                                    }
                                                </CardContent>
                                            </Card>
                                        );
                                    })
                                }
                            </Masonry>
                        )}
                </ContentWrapper>
            </PageWrapper>
        </>
    )
};

export default Awards;
