// @ts-nocheck
import {NavigationBar} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import {auth, competitionDocumentRef, playerCollection} from "src/config/firebase";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {Breadcrumbs, Button, useMediaQuery, useTheme} from "@mui/material";
import {useAuthState} from "react-firebase-hooks/auth";
import React from "react";
import {Link, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {calculateGross, calculateGrossToPar, calculateNett, calculateNettToPar} from "src/screens/Player";
import {DataGrid} from '@mui/x-data-grid';

const eventIdsWith18Holes = ['kilspindie_fourball', 'craigielaw_fourball'];

const Leaderboard = () => {
    const {competitionId} = useParams();
    if (!competitionId) throw new Error(`Expected a competitionId`);

    const theme = useTheme();
    const [user] = useAuthState(auth);
    const [players] = useCollectionData(playerCollection)
    const [competition] = useDocumentData(competitionDocumentRef(competitionId))
    const mediumAndUp = useMediaQuery(theme.breakpoints.up('md'));

    if (!user || !players || !competition) return null;

    const rows = players.map(player => {
        const matches = player.competitionHistory
            .filter(comp => comp.competitionId === competitionId)
            .flatMap(comp => comp.matches)
            .filter(m => eventIdsWith18Holes.includes(m.eventId));

        return ({
            id: player.id,
            name: player.name,
            team: player.team,
            gross_score: calculateGross(matches),
            gross_to_par: calculateGrossToPar(matches),
            nett_score: calculateNett(matches),
            nett_to_par: calculateNettToPar(matches)
        });
    });

    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <ContentWrapper>
                    <Breadcrumbs sx={{mt: -3, mb: 1}}>
                        <Button component={Link}
                                to={`/`}
                                style={{color: 'inherit', textDecoration: 'none'}}
                                startIcon={<ArrowBackIcon fontSize={"small"}/>}>
                            Home
                        </Button>
                    </Breadcrumbs>

                    <DataGrid
                        sx={{m: 0}}
                        disableColumnFilter={true}
                        disableRowSelectionOnClick={true}
                        disableColumnMenu={true}
                        disableColumnResize={true}
                        disableMultipleRowSelection={true}
                        initialState={{
                            sorting: {
                                sortModel: [{
                                    field: 'gross_to_par',
                                    sort: 'asc'
                                }]
                            },
                            columns: {
                                columnVisibilityModel: {
                                    gross_score: mediumAndUp,
                                    nett_score: mediumAndUp,
                                }
                            }
                        }}
                        density="compact"
                        columns={[
                            {field: 'name', headerName: 'Player', flex: 2},
                            {field: 'team', headerName: 'Team', flex: 1, sortable: false},
                            {
                                field: 'gross_to_par',
                                headerName: 'Gross',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                type: 'number',
                                valueGetter: val => parseInt(val),
                            },
                            {
                                field: 'nett_to_par',
                                headerName: 'Nett',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                type: 'number',
                                valueGetter: val => parseInt(val),
                            },
                            {
                                field: 'gross_score',
                                headerName: 'Gross score',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                type: 'number',
                                valueGetter: val => parseInt(val),
                            },
                            {
                                field: 'nett_score',
                                headerName: 'Nett score',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                type: 'number',
                                valueGetter: val => parseInt(val),
                            },
                        ]}
                        rows={rows}
                    />
                </ContentWrapper>
            </PageWrapper>
        </>
    )
};

export default Leaderboard;
