export const firebaseConfig = {
  apiKey: "AIzaSyDubQlNvivLWg0RF4L3P00Epn-AniAaFzU",
  authDomain: "ryder-cup-custom.firebaseapp.com",
  databaseURL: "https://ryder-cup-custom-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ryder-cup-custom",
  storageBucket: "ryder-cup-custom.appspot.com",
  messagingSenderId: "403999662303",
  appId: "1:403999662303:web:1f20d68835bdc135a4c96a",
  measurementId: "G-NTKQE26Z4J"
};
