// @ts-nocheck
import EventNoteIcon from '@mui/icons-material/EventNote';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuIcon from "@mui/icons-material/Menu";
import {
    Drawer,
    FormControl,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    NativeSelect,
    PaletteMode
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {createContext, useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {competitionCollection, competitionDocumentRef} from "src/config/firebase";


type NavigationBar = {
    children: React.ReactNode;
}

type NavigationLink = {
    label: string;
    href: string;
    icon: React.ReactNode;
};

const NavLink = ({label, href, icon}: NavigationLink) => (
    <ListItem disablePadding>
        <ListItemButton component={Link} to={href}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={label}/>
        </ListItemButton>
    </ListItem>
);

type ColourModeContextProps = {
    toggleColourMode: () => void;
    mode: PaletteMode;
};

const competitionLinks = (competitionId: string): NavigationLink[] => [
    {
        href: `/`,
        label: 'Matches',
        icon: <EventNoteIcon/>
    },
    {
        href: `/competition/${competitionId}/players`,
        label: 'Players',
        icon: <GroupsIcon/>
    },
    {
        href: `/leaderboard/${competitionId}`,
        label: 'Leaderboard',
        icon: <EmojiEventsIcon/>
    }
]

const otherLinks = (): NavigationLink[] => [
    {
        href: `/awards`,
        label: 'Awards',
        icon: <EmojiEventsIcon/>
    }
]


export const ColourModeContext = React.createContext<ColourModeContextProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleColourMode: () => {
    },
    mode: "light"
});

// Define types
type SelectedCompetitionContextType = {
    selectedCompetitionId: string;
    setSelectedCompetitionId: (id: string) => void;
};

const SelectedCompetitionContext = createContext<SelectedCompetitionContextType>({
    selectedCompetitionId: 'ryder-cup-2024',
    setSelectedCompetitionId: () => {
    },
});

// Create a provider component to wrap your application
export const SelectedCompetitionProvider = ({children}: { children: React.ReactNode }) => {
    const [selectedCompetitionId, setSelectedCompetitionId] = useState('ryder-cup-2024');

    return (
        <SelectedCompetitionContext.Provider value={{selectedCompetitionId, setSelectedCompetitionId}}>
            {children}
        </SelectedCompetitionContext.Provider>
    );
};

// Custom hook to access the selected competition ID
export const useSelectedCompetition = () => useContext(SelectedCompetitionContext);

export const NavigationBar: React.FC<NavigationBar> = ({children}) => {
    const location = useLocation();
    const [competitions] = useCollectionData(competitionCollection)
    const {selectedCompetitionId, setSelectedCompetitionId} = useSelectedCompetition();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [competition] = useDocumentData(competitionDocumentRef(selectedCompetitionId))

    useEffect(() => {
        setDrawerOpen(false);
    }, [setDrawerOpen, location.pathname]);

    const toggleDrawer = () => {
        setDrawerOpen(prev => !prev);
    };

    if (!competitions) return null;
    if (!competition) return null;

    return (
        <>
            <AppBar
                variant="elevation"
                position="static"
                sx={{background: theme => theme.palette.header?.main}}
            >
                <Toolbar>
                    <Typography
                        component={Link}
                        to={"/"}
                        variant="h6"
                        sx={{
                            color: "inherit",
                            textDecoration: "none",
                            flexGrow: 1,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}
                    >
                        <img alt={"Ryder Cup logo"} src={"/logos/logo-large.png"} style={{
                            height: '80px',
                            position: 'absolute',
                            top: '0.5rem',
                            left: '1rem',
                        }}/>
                    </Typography>
                    <IconButton onClick={toggleDrawer} size="large" edge="start" aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            {children}

            <Drawer anchor={"right"} open={drawerOpen} onClose={toggleDrawer}>
                <Box sx={{height: "100%", display: "flex", flexDirection: "column", width: 275}}>
                    <Box sx={{m: 1}}>
                        <List>
                            <ListSubheader>Competition</ListSubheader>

                            <FormControl fullWidth>
                                <NativeSelect
                                    value={selectedCompetitionId}
                                    onChange={(event) => setSelectedCompetitionId(event.target.value)}
                                    sx={{
                                        background: 'white',
                                        padding: '0.25rem 1rem',
                                        border: '1px solid #dfdfdf',
                                        borderRadius: '4px',
                                        '&:before': {content: 'none'}
                                    }}
                                >
                                    {competitions.map(competition => (
                                        // @ts-ignore
                                        <option value={competition.id}>{competition.name}</option>))}
                                </NativeSelect>
                            </FormControl>

                            {
                                // @ts-ignore
                                competitionLinks(competition.id).map(link => (
                                    <NavLink {...link} key={link.label}/>
                                ))
                            }
                        </List>
                    </Box>

                    <List>
                        <ListSubheader>Lifetime</ListSubheader>
                        {
                            otherLinks().map(link => (
                                <NavLink {...link} key={link.label}/>
                            ))
                        }
                    </List>
                </Box>
            </Drawer>
        </>
    );
};
